import { useEffect, useMemo, useState } from 'react';
import { Capability } from 'homey-lib/webpack/index.js';

import { DeviceTile } from './DeviceTile';

import { IndicatorLight } from './IndicatorLight';
import { IndicatorLock } from './IndicatorLock';
import { IndicatorSpeaker } from './IndicatorSpeaker';
import { IndicatorThermostat } from './IndicatorThermostat';
import { IndicatorAlarm } from './IndicatorAlarm';
import { IndicatorAlarmSpecific } from './IndicatorAlarmSpecific';
import { IndicatorNumber } from './IndicatorNumber';
import { IndicatorBattery } from './IndicatorBattery';

export const dangerCapabilityIdList = ['alarm_co', 'alarm_fire', 'alarm_smoke'];

export const defaultCapabilityIdOrderList = [
  'alarm_motion',
  'alarm_contact',
  'alarm_co2',
  'alarm_pm25',
  'alarm_heat',
  'alarm_water',
  'measure_temperature',
  'measure_co',
  'measure_co2',
  'measure_pm25',
  'alarm_generic',
  'alarm_tamper',
  'alarm_night',
  'measure_power',
  'measure_humidity',
  'measure_pressure',
  'meter_power',
  'meter_water',
  'meter_gas',
  'meter_rain',
  'measure_voltage',
  'measure_current',
  'measure_luminance',
  'measure_ultraviolet',
  'measure_water',
  'measure_noise',
  'measure_rain',
  'measure_wind_strength',
  'measure_wind_angle',
  'measure_gust_strength',
  'measure_gust_angle',
];

export function Indicator({ device, capabilities, tileSize }) {
  const deviceClass = getIndicatorDeviceClass(device);
  const uiIndicator = device.uiIndicator;

  const { isDangerAlarm } = useDangerAlarm({ capabilities });

  const { Component, capabilityId } = useMemo(() => {
    if (capabilities == null) return {};
    const capabilityList = Object.values(capabilities);

    const capabilityId = uiIndicator ?? getDefaultCapabilityId(capabilityList);

    if (capabilityId === '.none') return {};

    const returnValue = {
      Component: null,
      capabilityId: capabilityId,
    };

    switch (true) {
      case deviceClass === 'light':
        returnValue.Component = IndicatorLight;
        break;
      case deviceClass === 'lock':
        returnValue.Component = IndicatorLock;
        break;
      case deviceClass === 'speaker':
        returnValue.Component = IndicatorSpeaker;
        break;
      case deviceClass === 'thermostat':
        returnValue.Component = IndicatorThermostat;
        break;
      case capabilityId != null && isBatteryIndicator(capabilityId):
        returnValue.Component = IndicatorBattery;
        break;
      case capabilityId != null &&
        isNumberIndicator(capabilityId) &&
        tileSize === DeviceTile.size.medium:
        returnValue.Component = IndicatorNumber;
        break;
      case capabilityId != null && isSpecificAlarmIndicator(capabilityId):
        returnValue.Component = IndicatorAlarmSpecific;
        break;
      case deviceClass === 'sensor':
      case capabilityList.some((capability) => {
        return capability.id.startsWith('alarm_');
      }):
        returnValue.Component = IndicatorAlarm;
        break;
      default:
        break;
    }

    return returnValue;
  }, [capabilities, deviceClass, uiIndicator, tileSize]);

  if (isDangerAlarm === true) {
    return <IndicatorAlarm device={device} capabilities={capabilities} />;
  }

  if (Component == null) return null;

  return <Component device={device} capabilities={capabilities} capabilityId={capabilityId} />;
}

function isNumberIndicator(capabilityId) {
  return capabilityId.startsWith('meter_') || capabilityId.startsWith('measure_');
}

function isSpecificAlarmIndicator(capabilityId) {
  return capabilityId.startsWith('alarm_');
}

function isBatteryIndicator(capabilityId) {
  return (
    Capability.isInstanceOfId(capabilityId, 'alarm_battery') ||
    Capability.isInstanceOfId(capabilityId, 'measure_battery')
  );
}

function getIndicatorDeviceClass(device) {
  switch (device.virtualClass) {
    case 'light':
      return device.virtualClass;
    default:
      return device.class;
  }
}

function getDefaultCapabilityId(capabilityList) {
  let higestPrecedenceCapabilityId = null;
  let prevIndex = Infinity;

  for (const capability of capabilityList) {
    const [baseId] = capability.id.split('.');
    const index = defaultCapabilityIdOrderList.indexOf(baseId);

    if (index === -1) continue;

    // Higher precedence.
    if (index < prevIndex) {
      prevIndex = index;
      higestPrecedenceCapabilityId = capability.id;
      // Continue here because we are overwriting the prevIndex.
      continue;
    }

    // Same precedence.
    if (index === prevIndex) {
      // This means the base id should be used. Because we have encountered for example
      // measure_power and measure_power.foo or measure_power.foo and measure_power. We
      // need this case because devices can also only have measure_power.foo and not the base
      // capability.
      if (higestPrecedenceCapabilityId !== baseId) {
        higestPrecedenceCapabilityId = baseId;
      }
    }
  }

  return higestPrecedenceCapabilityId;
}

function useDangerAlarm({ capabilities }) {
  const [isDangerAlarm, setIsDangerAlarm] = useState(null);

  useEffect(() => {
    if (capabilities != null) {
      let currentIsDangerAlarm = null;
      const capabilitiesUnregisterList = [];
      const capabilityList = Object.values(capabilities);

      capabilityList.forEach((capability) => {
        const baseId = capability.id.split('.')[0];

        if (dangerCapabilityIdList.includes(baseId)) {
          const unregister = capability.onChange(({ value }) => {
            setIsDangerAlarm(value);
          });

          if (capability.value === true) {
            currentIsDangerAlarm = capability.value;
          }

          capabilitiesUnregisterList.push(unregister);
        }
      });

      if (currentIsDangerAlarm === true) {
        setIsDangerAlarm(currentIsDangerAlarm);
      }

      return function () {
        capabilitiesUnregisterList.forEach((unregister) => unregister());
      };
    }
  }, [capabilities]);

  return { isDangerAlarm };
}
