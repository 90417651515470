import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { AnimatePresence } from 'framer-motion';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { useI18n } from '../../hooks/useI18nFormatters';
import { useAuth } from '../../store/AuthStore';
import { useApi } from '../../store/HomeyStore';
import { useDevicesLoading } from '../../store/devices/useDevices';
import { useFlowsLoading } from '../../store/flow/useFlows';
import { useAdvancedFlowsLoading } from '../../store/advanced-flow/useAdvancedFlows';
import { useUsersLoading } from '../../store/users/useUsers';
import { useQuery } from '../../hooks/useQuery';
import { useLayoutContextState } from '../../LayoutContext';
import { useMoodsLoading } from '../../store/moods/useMoods';
import { useUserMe } from '../../store/user-me/useUserMe';

import { RouteManager } from '../../RouteManager';

import { theme } from '../../theme/theme';

import { Layout } from '../../Layout';
import { Scroll } from '../../components/common/Scroll';
import { Heading1Bold } from '../../components/common/text/Heading1Bold';
import { Heading3Bold } from '../../components/common/text/Heading3Bold';

import { Weather } from './Weather';
import { Users } from './Users';
import { FavoriteDevices } from './FavoriteDevices';
import { FavoriteFlows } from './FavoriteFlows';
import { FavoriteMoods } from './FavoriteMoods';
import { PresenceDialog } from './PresenceDialog';
import { Controls } from '../../components/controls/Controls';
import { PairingDelegator } from '../../components/pairing/PairingDelegator';
import { DeviceSettingsDialog } from '../devices/settings/DeviceSettingsDialog';
import { PremiumRequiredDialog } from '../../components/premium/PremiumRequiredDialog';

export function HomePage() {
  const { i18n } = useI18n();
  const location = useLocation();

  const auth = useAuth();
  const { api } = useApi();
  const isDevicesLoading = useDevicesLoading();
  const isMoodsLoading = useMoodsLoading();
  const isFlowsLoading = useFlowsLoading();
  const isAdvancedFlowsLoading = useAdvancedFlowsLoading();
  const isUsersLoading = useUsersLoading();
  const userMe = useUserMe();

  const { layoutContextState } = useLayoutContextState();

  const deviceMatch = useRouteMatch('/homeys/:homeyId/:deviceId');
  const deviceId = deviceMatch?.params?.deviceId || null;

  const params = useQuery();
  const dialogType = params.get('dialog');
  const dialogResourceKey = params.get('key');

  const [selectedDeviceId, setSelectedDeviceId] = useState(deviceId);

  useEffect(() => {
    setSelectedDeviceId(deviceId);
  }, [deviceId]);

  const isSiblingLoading =
    isDevicesLoading ||
    isMoodsLoading ||
    isFlowsLoading ||
    isAdvancedFlowsLoading ||
    isUsersLoading;

  const message = getGreeting({ i18n, auth });

  return (
    <Layout
      isRightOpen={selectedDeviceId != null}
      content={
        <Scroll
          style={{
            '--scrollbar-thumb-background': layoutContextState.scrollbarThumbBackgroundMV,
          }}
        >
          <HomePage.Content>
            <Heading1Bold>{message}</Heading1Bold>

            <Weather />

            <HomePage.Section>
              <Users
                isSiblingLoading={isSiblingLoading}
                onUserPress={(event, user) => {
                  RouteManager.toUserPresence(user.id);
                }}
              />
            </HomePage.Section>

            <HomePage.Section>
              <Heading3Bold>{i18n.messageFormatter(`home.favoriteDevices`)}</Heading3Bold>

              <FavoriteDevices
                selectedDeviceId={selectedDeviceId}
                isSiblingLoading={isSiblingLoading}
              />
            </HomePage.Section>

            {api?.features.moods.hasMoods === true && userMe?.favoriteMoods?.length > 0 && (
              <HomePage.Section>
                <Heading3Bold>{i18n.messageFormatter(`home.favoriteMoods`)}</Heading3Bold>

                <FavoriteMoods isSiblingLoading={isSiblingLoading} />
              </HomePage.Section>
            )}

            <HomePage.Section>
              <Heading3Bold>{i18n.messageFormatter(`home.favoriteFlows`)}</Heading3Bold>

              <FavoriteFlows isSiblingLoading={isSiblingLoading} />
            </HomePage.Section>
          </HomePage.Content>
        </Scroll>
      }
      right={<Controls deviceId={selectedDeviceId} onClosePress={() => RouteManager.toHome()} />}
    >
      <AnimatePresence>
        {dialogType === RouteManager.dialogRoute.userPresence && (
          <PresenceDialog userId={dialogResourceKey} />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {dialogType === RouteManager.dialogRoute.deviceSettings && (
          <DeviceSettingsDialog deviceId={dialogResourceKey} />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {location.state?.pairDialog != null && (
          <PairingDelegator state={location.state.pairDialog} />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {location.state?.premiumRequiredDialog === true && <PremiumRequiredDialog />}
      </AnimatePresence>
    </Layout>
  );
}

function getGreeting({ i18n, auth }) {
  const now = new Date();
  const hours = now.getHours();

  let message;

  if (hours <= 4) {
    message = i18n.messageFormatter(`greeting.night`, {
      name: auth.user.firstname,
    });
  } else if (hours < 12) {
    message = i18n.messageFormatter(`greeting.morning`, {
      name: auth.user.firstname,
    });
  } else if (hours < 18) {
    message = i18n.messageFormatter(`greeting.afternoon`, {
      name: auth.user.firstname,
    });
  } else {
    message = i18n.messageFormatter(`greeting.evening`, {
      name: auth.user.firstname,
    });
  }

  return `${message}!`;
}

HomePage.Content = styled.section`
  padding: 0 ${theme.suo(3)} ${theme.suo(2)};
`;

HomePage.Section = styled.section`
  padding-bottom: ${theme.suo(2)};

  ${Heading3Bold.S.Root} {
    margin-bottom: ${theme.su(2)};
  }
`;

export default HomePage;
